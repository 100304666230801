export const sorobanMainnetRpcEndpoint: string = `https://mainnet.stellar.validationcloud.io/v1/r39vq61bkyEQIpOaurlWjJYr77HrcayUC_4sblcB_K8`;
export const blubIssuerPublicKey: string =
  "GCX6LOZ6ZEXBHLTPOPP2THN74K33LMT4HKSPDTWSLVCF4EWRGXOS7D3V";
export const blubSignerPublicKey: string =
  "GDJ2BJCYLWFCLDVF4THQLQDTTQGBJ4UJ3OQTJ3IHJ5L3E2ZTLSCIGOSH";
export const lpSignerPublicKey: string =
  "GDJ2BJCYLWFCLDVF4THQLQDTTQGBJ4UJ3OQTJ3IHJ5L3E2ZTLSCIGOSH";
export const treasureAddress: string =
  "GC6UELQOGTXPOKGIG52T5ZC42YGNZIZATMJF7A3HM3TGRFOC3WSPMWXA";
export const BACKEND_API = process.env.REACT_APP_BACKEND_URL;
export const JEWEL_TOKEN = "WHLAQUA";

export const aquaAssetCode = "AQUA";
export const aquaAssetIssuer =
  "GBNZILSTVQZ4R7IKQDGHYGY2QXL5QOFJYQMXPKWRRM5PAV7Y4M67AQUA";

export const whlAssetCode = "WHLAQUA";
export const whlAquaIssuer =
  "GCX6LOZ6ZEXBHLTPOPP2THN74K33LMT4HKSPDTWSLVCF4EWRGXOS7D3V";
