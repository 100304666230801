export enum SERVICE_TYPES {
  LOAD_STATE = "LOAD_STATE",
  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",
  SIGN_TRANSACTION = "SIGN_TRANSACTION",
  REJECT_TRANSACTION = "REJECT_TRANSACTION",
  GRANT_ACCESS = "GRANT_ACCESS",
  REJECT_ACCESS = "REJECT_ACCESS",
}

export enum EXTERNAL_SERVICE_TYPES {
  REQUEST_ACCESS = "REQUEST_ACCESS",
  SIGN_TRANSACTION = "SUBMIT_TRANSACTION",
  REQUEST_CONNECTION_STATUS = "REQUEST_CONNECTION_STATUS",
}

export enum TX_STATUS {
  pending = "pending",
  signed = "signed",
  rejected = "rejected",
}

export const EXTERNAL_MSG_REQUEST = "LOBSTR_EXTERNAL_MSG_REQUEST";
export const EXTERNAL_MSG_RESPONSE = "LOBSTR_EXTERNAL_MSG_RESPONSE";

export const CONNECTION_KEY = "LOBSTR_CONNECTION_KEY";

declare const DEV_SERVER: string;
const _DEV_SERVER = DEV_SERVER;
export { _DEV_SERVER as DEV_SERVER };

declare const DEV_EXTENSION: string;
const _DEV_EXTENSION = DEV_EXTENSION;
export { _DEV_EXTENSION as DEV_EXTENSION };
